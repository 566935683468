import React, { Component } from 'react';
import styles from './Group.module.css';
import url from '../../../../config';

class Group extends Component {
  constructor(props) {
    super(props);

    var selectedClass = (props.current === props.data.id) ? styles.selected : '';
    this.state = { selectedClass: selectedClass }
  }

  render() {
    var selectedClass = (this.props.current === this.props.data.id) ? styles.selected : '';
    return (
      <div onClick={() => { this.props.click(this.props.data.id, this.props.data.alt) }} className={`${styles.element} ${selectedClass}`}>
        <table cellPadding='0' cellSpacing='0'>
          <tbody>
            <tr>
              <td className={styles.imagecontainer}>
                <div className={styles.image} style={{ backgroundImage: 'url("' + url + 'poster.php?id=' + this.props.data.id + '&session=' + this.props.session + '&user=' + this.props.user + '")' }}>

                </div>
              </td>
              <td className={styles.label}>
                {this.props.data.label}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Group;