import React from 'react';
import styles from './Login.module.css';

const Login = () => {
  return (
    <div>
      <div className={styles.form}>Login</div>
    </div>
  );
}

export default Login;