import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadGroups } from '../../store/actions';
import styles from './Side.module.css';

class Side extends Component {
    constructor(props) {
        super(props);
        const { user } = props.match.params;

        this.state = {
            user: user
        };
    }

    makeLink = () => {
        var link;
        if (typeof this.state.user === 'undefined') {
            link = "/";
        } else {
            link = '/' + this.state.user + "/";
        }
        return link;
    }

    componentDidMount() {
        if (this.state.user === "video" || this.state.user === "token") {
            this.setState({ user: undefined })
            this.props.loadGroups(undefined);
        } else {
            this.props.loadGroups(this.state.user);
        }
    }



    render() {
        return (
            <div className={styles.sidemenu}>
                <Link className={styles.link} onClick={() => { }} to={this.makeLink}>
                    <div className={styles.el}>
                        <i className={`${'fa-play-circle'} ${'far'} ${styles.icon}`} />Oversigt
                    </div>
                </Link>
                {this.props.groupsData.map((item, i) =>
                    // <Link className={styles.link} onClick={() => { }} to={'/video/' + item.elements[0].videoid}>
                    <div key={'jky' + i} className={styles.uel}>
                        {item.name}
                    </div>
                    // </Link>
                )}
                <div className={styles.bottombox}>
                    <a className={styles.link} href="https://humanhouse.com/om/">
                        <div className={`${styles.sel} ${styles.line}`}>
                            Om Human House
                        </div>
                    </a>
                    <a className={styles.link} href="https://humanhouse.com/konsulenter/">
                        <div className={styles.sel}>
                            Mød en konsulent
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        groupsData: state.groups.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadGroups: (user) => dispatch(loadGroups(user))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Side);