import React, { Component } from 'react';
import styles from './Files.module.css'
import url from '../../../config';

class Files extends Component {
  state = {
    expand: true
  }

  openHandler = () => {
    this.setState({ expand: !this.state.expand });

  }

  render() {
    return (
      <div className={`${styles.box} ${this.state.expand ? styles.opened : ''}`}>
        <div className={styles.title} onClick={this.openHandler}>
          <i className={this.state.expand ? 'fas fa-minus-circle' : 'fas fa-plus-circle'}></i> Filer
      </div>
        <div className={styles.main}>
          {this.props.files.map((item, i) =>
            <div key={'dfg' + i}>
              <a target='_blank' className={styles.link} href={url + 'file.php?id=' + this.props.video + '&file=' + item.id + '&session=' + this.props.session}>{item.name}</a>
            </div>
          )}
        </div>
      </div>

    );
  }
}

export default Files;
