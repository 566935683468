import React, { Component } from 'react';
import videojs from 'video.js';
import "../../../../node_modules/video.js/dist/video-js.css";
import './Player.css';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';

class VideoPlayer extends Component {

  componentDidMount() {
    // instantiate Video.js
    require('@silvermine/videojs-quality-selector')(videojs);
    this.player = videojs(this.videoNode, {
      ...this.props.conf,
      languages: {
        da: {
          Play: 'Afspil',
          Pause: 'Pause',
          "Subtitles": "Undertekster",
          "subtitles off": "fra",
        }
      },
      language: 'da',
      textTrackSettings: false,
      controlBar: {
        children: [
          'playToggle',
          'volumePanel',
          'progressControl',
          'subsCapsButton',
          'qualitySelector',
          'fullscreenToggle',
        ],
      }
    });
    // this.player.on('timeupdate', () => { console.log(this.player.currentTime()); });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return true;
  // }

  componentDidUpdate() {
    var tracks = this.player.textTracks();
    this.player.removeRemoteTextTrack(tracks[0]);

    if (this.props.subs) {
      let captionOption = {
        kind: 'captions',
        srclang: 'da',
        label: 'til',
        src: this.props.track
      };

      this.player.addRemoteTextTrack(captionOption, true);
      this.player.poster(this.props.poster);
    }
    if (this.props.alt) {
      this.player.src([{
        src: this.props.src,
        type: 'video/mp4',
        label: 'Høj',
        selected: true
      }, {
        src: this.props.src + '&q=1',
        type: 'video/mp4',
        label: 'Lav'
      }])
    } else {
      this.player.src([{
        src: this.props.src,
        type: 'video/mp4',
        label: 'Høj',
        selected: true
      }])
    }

    // this.setState({ alt: this.props.alt });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  timeupdateHandler() {

  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video onContextMenu={e => e.preventDefault()} style={{ width: ' ' }} ref={node => this.videoNode = node} className="video-js">
          </video>
        </div>
      </div>
    )
  }
}

export default VideoPlayer;