var url;
if (process.env.NODE_ENV === 'production') {
  // Code will only appear in production build.
  url = window.location.origin + '/backend/';
}

if (process.env.NODE_ENV !== 'production') {
  // Code will be removed from production build.
  url = 'http://localhost/backend/';
}

export default url;