import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './App.module.css';

import Video from './components/Video/Video';
import Top from './components/Top/Top';
import Side from './components/Side/Side';
import Front from './components/Front/Front';
import LoginToken from './components/LoginToken/LoginToken';
import Login from './components/Login/Login';
import File from './components/File/File';
import DirectVideo from './components/Video/DirectVideo';
import DownloadVideo from './components/Video/DownloadVideo';
import EmbedVideo from './components/Video/Embed';
import { reAuth } from './store/actions';

class App extends Component {

  state = {
    showMenu: window.location.pathname.includes('direct') || window.location.pathname.includes('embed') || window.location.pathname.includes('download') || window.location.pathname.includes('file'),
    showTop: window.location.pathname.includes('embed')
  }

  componentDidMount() {
    setInterval(() => {
      if (this.props.authData.session !== null) {
        this.props.reAuth(this.props.authData.session);
      }
      // }
    }, 60000);
  }

  render() {
    return (
      <div className={styles.container}>
        <BrowserRouter>
          <div className={styles.main} style={this.state.showMenu ? { gridArea: "1 / 1 / 2 / 3" } : {}}>
            {!this.state.showTop &&
              <Top user={this.props.authData.user} />
            }
            <Switch>
              <Route path="/token/:token/:dist?/:vars?" exact component={LoginToken} />
              <Route path="/:user?/video/:id/" exact component={Video} />
              <Route path="/direct/:id/" exact component={DirectVideo} />
              <Route path="/download/:id/" exact component={DownloadVideo} />
              <Route path="/file/:id/" exact component={File} />
              <Route path="/embed/:id/" exact component={EmbedVideo} />
              <Route path="/login" exact component={Login} />
              <Route path="/:user?/" exact component={Front} />
              <Route exact>
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          {!this.state.showMenu &&
            <div className={styles.sidemenu}>
              <Route path="/:user?/" component={Side} />
            </div>}
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authData: state.auth.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reAuth: (session) => dispatch(reAuth(session))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);