import React from 'react';
import styles from './Card.module.css';
import { Link } from 'react-router-dom';
import url from '../../../config';

const Card = (props) => {
    const makeLink = () => {
        var link;
        if (typeof props.user === 'undefined') {
            link = "/video/" + props.videoid;
        } else {
            link = '/' + props.user + "/video/" + props.videoid;
        }
        return link;
    }

    return (
        <Link className={styles.link} onClick={() => { }} to={makeLink}>
            <div className={styles.linkbox}>
                <div className={styles.main}>
                    <div className={styles.cover} style={{ backgroundImage: 'url("' + url + 'poster.php?id=' + props.videoid + '&session=' + props.session + '&user=' + props.user + '")' }}>

                    </div>
                </div>
                <div className={styles.name}>
                    {props.label}
                </div>
            </div>
        </Link>
    );
}

export default Card;