import * as actionTypes from '../actions/actionsTypes';

const session = localStorage.getItem('session');
const user = localStorage.getItem('user');

const initialState = {
  data: {
    session: session,
    token: null,
    user: user
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SESSION:
      return {
        ...state,
        data: { ...action.data }
      }
    default:
      return state;
  }
}

export default reducer;