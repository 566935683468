import React, { Component } from 'react';
import styles from './File.module.css';
import url from '../../config';
import { connect } from 'react-redux';
import { loadFile } from '../../store/actions';

class File extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      src: url + 'file.php?file=' + id + '&session=false',
      id: id
    }
  }

  componentDidMount() {
    this.props.loadFile(this.state.id);
  }

  render() {
    return (
      <div className={styles.all}>
        {this.props.fileData.filereal !== "Du kan ikke hente filen" ?
          <a href={this.state.src}><img src={window.location.origin + '/file-solid.svg'} width="50" /><br />
            {this.props.fileData.filereal}</a> :
          <div>{this.props.fileData.filereal}</div>}
        <div className={styles.footer}>
          <a className={styles.link} href="https://humanhouse.com/om/">Om Human House</a>
          <a className={styles.link} href="https://humanhouse.com/konsulenter/">Mød en konsulent</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fileData: state.file.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadFile: (file) => dispatch(loadFile(file))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(File);