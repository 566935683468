import React, { Component } from 'react';
import VideoPlayer from './Player/Player';
import Groups from './Groups/Groups';
import BottomText from './BottomText/BottomText';
import styles from './Video.module.css';
import { connect } from 'react-redux';
import { loadVideoData } from '../../store/actions';
import Empty from '../Empty/Empty';
import Files from './Files/Files';
import url from '../../config';

class Video extends Component {

  constructor(props) {
    super(props);

    const { id, user } = props.match.params;

    this.state = {
      videoJsOptions: {
        autoplay: true,
        controls: true,
        muted: false,
        aspectRatio: '16:9'
      },
      track: url + 'subs.php?id=' + id + '&session=' + props.authData.session + '&user=' + user,
      src: url + 'video.php?video=' + id + '&session=' + props.authData.session + '&user=' + user,
      poster: url + 'poster.php?id=' + id + '&session=' + props.authData.session + '&user=' + user,
      alt: props.videoData.alt,
      id: id,
      user: user
    }
  }

  componentDidMount() {
    this.props.loadVideoData(this.state.id, this.state.user);
  }

  linkHandler = (link) => {
    if (typeof this.state.user === 'undefined') {
      this.props.history.push('/video/' + link);
    } else {
      this.props.history.push('/' + this.state.user + '/video/' + link);
    }

    this.props.loadVideoData(link, this.state.user);
    this.setState({
      src: url + 'video.php?video=' + link + '&session=' + this.props.authData.session + '&user=' + this.state.user,
      poster: url + 'poster.php?id=' + link + '&session=' + this.props.authData.session + '&user=' + this.state.user,
      track: url + 'subs.php?id=' + link + '&session=' + this.props.authData.session + '&user=' + this.state.user,
      id: link
    });
  }

  render() {
    return (
      <div className={styles.all}>
        <div className={styles.container}>
          <div key={this.props.id} className={styles.videoarea}>
            {this.props.videoData.show ?
              <VideoPlayer conf={this.state.videoJsOptions} track={this.state.track} src={this.state.src} poster={this.state.poster} subs={this.props.videoData.subs} alt={this.props.videoData.alt} /> :
              <div style={{ backgroundColor: 'Black', height: '200px' }}></div>
            }
            <BottomText title={this.props.videoData.label} text={this.props.videoData.text} />
          </div>
          <div className={styles.textarea}>
            <Groups session={this.props.authData.session} user={this.state.user} name={this.props.videoData.groupname} groups={this.props.videoData.group} click={this.linkHandler} sel={this.state.id} />
          </div>
        </div>
        {this.props.videoData.files.length !== 0 &&
          <Files files={this.props.videoData.files} session={this.props.authData.session} video={this.state.id} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videoData: state.video.data,
    authData: state.auth.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadVideoData: (video, user) => dispatch(loadVideoData(video, user))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Video);