import axios from 'axios';
import * as actionTypes from './actionsTypes';
import url from '../../config';

export const loadVideoData = (video, user) => {
  return (dispatch, getState) => {
    const session = getState().auth.data.session;
    axios.get(url + 'info.php?video=' + video + '&session=' + session + '&user=' + user)
      .then(response => {
        dispatch({ type: actionTypes.LOAD_VIDEO_DATA, data: response.data });
      });
  }
};

export const loadDirectVideoData = (video) => {
  return (dispatch, getState) => {
    const session = getState().auth.data.session;
    axios.get(url + 'directinfo.php?video=' + video)
      .then(response => {
        dispatch({ type: actionTypes.LOAD_VIDEO_DATA, data: response.data });
      });
  }
};