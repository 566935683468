import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './Front.module.css';
import Card from './Card/Card';

class Front extends Component {
  constructor(props) {
    super(props);

    const { user } = props.match.params;

    this.state = {
      user: user
    }
  }

  render() {
    return (
      <div className={styles.front}>
        <div>
          {this.props.groupsData.map((group, i) =>
            <div key={i}>
              <div className={styles.title}>{group.name}</div>
              {group.elements.map((video, j) =>
                <Card user={this.state.user} key={i + '-' + j} session={this.props.authData.session} videoid={video.videoid} label={video.label} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupsData: state.groups.data,
    authData: state.auth.data
  };
};

export default connect(mapStateToProps)(Front);

