import axios from 'axios';
import * as actionTypes from './actionsTypes';
import url from '../../config';

export const loadFile = (file) => {
  return (dispatch, getState) => {
    const session = getState().auth.data.session;
    axios.get(url + 'fileinfo.php?file=' + file + '&session=false')
      .then(response => {
        dispatch({ type: actionTypes.LOAD_FILE_DATA, data: response.data.data });
      });
  }
};