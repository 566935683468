import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadDirectVideoData } from '../../store/actions';
import VideoPlayer from './Player/Player';
import BottomText from './BottomText/BottomText';
import Files from './Files/Files';
import styles from './DirectVideo.module.css';
import url from '../../config';

class DirectVideo extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      videoJsOptions: {
        autoplay: true,
        controls: true,
        muted: false,
        aspectRatio: '16:9'
      },
      track: url + 'directsubs.php?id=' + id,
      poster: url + 'directposter.php?video=' + id,
      src: url + 'direct.php?video=' + id,
      id: id
    }
  }

  componentDidMount() {
    this.props.loadVideoData(this.state.id);
  }

  render() {
    return (
      <div className={styles.all}>
        {this.props.videoData.show ?
          this.props.videoData.timeout ?
            <div><div className={styles.overlay}>Videoen er udløbet, kontakt HR eller din administrator.</div><img className={styles.timeout} src={url + 'directposter.php?video=' + this.state.id} /></div>
            :
            <VideoPlayer conf={this.state.videoJsOptions} track={this.state.track} src={this.state.src} poster={this.state.poster} subs={this.props.videoData.subs} alt={this.props.videoData.alt} />
          :
          <div style={{ backgroundColor: 'Black', height: '200px' }}></div>
        }
        <BottomText title={this.props.videoData.label} text={this.props.videoData.text} />
        {this.props.videoData.files.length !== 0 &&
          <Files files={this.props.videoData.files} session={false} video={this.state.id} />
        }
        <div className={styles.footer}>
          <a className={styles.link} href="https://humanhouse.com/om/">Om Human House</a>
          <a className={styles.link} href="https://humanhouse.com/konsulenter/">Mød en konsulent</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videoData: state.video.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadVideoData: (video) => dispatch(loadDirectVideoData(video))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(DirectVideo);