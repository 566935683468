import React, { Component } from 'react';
import Group from './Group/Group';
import styles from './Groups.module.css';

class Groups extends Component {
  render() {
    return (
      <div className={styles.videoside}>
        <div className={styles.groupTitle}>
          {this.props.name}
        </div>
        {this.props.groups.map((item, i) =>
          <Group
            key={i}
            data={item}
            user={this.props.user}
            session={this.props.session}
            click={this.props.click}
            current={this.props.sel}
          />
        )}
      </div>

    );
  }
}

export default Groups;