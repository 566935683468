import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadDirectVideoData } from '../../store/actions';
import VideoPlayer from './Player/Player';
import styles from './Embed.module.css';
import url from '../../config';

class Embed extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      videoJsOptions: {
        autoplay: true,
        controls: true,
        muted: false,
        fluid: true
      },
      track: url + 'directsubs.php?id=' + id,
      poster: url + 'directposter.php?video=' + id,
      src: url + 'direct.php?video=' + id,
      id: id
    }
  }

  componentDidMount() {
    this.props.loadVideoData(this.state.id);
  }

  render() {
    return (
      <div className={styles.body}>
        {this.props.videoData.show ?
          this.props.videoData.timeout ?
            <div><div className={styles.overlay}>Videoen er udløbet, kontakt HR eller din administrator.</div><img className={styles.timeout} src={url + 'directposter.php?video=' + this.state.id} /></div>
            :
            <VideoPlayer conf={this.state.videoJsOptions} track={this.state.track} src={this.state.src} poster={this.state.poster} subs={this.props.videoData.subs} alt={this.props.videoData.alt} />
          :
          <div style={{ backgroundColor: 'Black', height: '100%' }}></div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videoData: state.video.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadVideoData: (video) => dispatch(loadDirectVideoData(video))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Embed);