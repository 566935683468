import React from 'react';
import styles from './BottomText.module.css';

const BottomText = (props) => {
  return (<div className={styles.videobottom}>
    <span className={styles.title}>
      {props.title}
    </span>
    <div className={styles.text}>
      {props.text &&
        <span>
          {props.text.map((items, i) => <span key={i + 'fg'}>{items}<br /></span>)}
        </span>
      }
    </div>
  </div>
  );
}

export default BottomText;