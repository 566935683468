import React, { Component } from 'react';
import styles from './DownloadVideo.module.css';
import url from '../../config';

class DownloadVideo extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      src: url + 'download.php?video=' + id
    }
  }

  render() {
    return (
      <div className={styles.all}>
        <a href={this.state.src}><img src={window.location.origin + '/file-video-solid.svg'} width="50" /><br />
        Video</a>
        <div className={styles.footer}>
          <a className={styles.link} href="https://humanhouse.com/om/">Om Human House</a>
          <a className={styles.link} href="https://humanhouse.com/konsulenter/">Mød en konsulent</a>
        </div>
      </div>
    );
  }
}

export default DownloadVideo;