import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import groupsReducer from './store/reducers/groups';
import videoReducer from './store/reducers/video';
import authReducer from './store/reducers/auth';
import fileReducer from './store/reducers/file';

const rootReducer = combineReducers({
  groups: groupsReducer,
  video: videoReducer,
  auth: authReducer,
  file: fileReducer
})

const login = store => {
  return next => {
    return action => {
      const result = next(action);
      if (result != undefined)
        // console.log(result);
        return result;
    }
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(login, thunk))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
