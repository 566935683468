import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Top.module.css';
import logo from './am_logo.png';

const Top = (props) => {
    return (
        <div className={styles.top}>
            {/* <div className={styles.login}>
                <Link to="/login">
                    <i className={`${'fa-user'} ${'far'} ${styles.icon}`} />
                    {props.user == null ? <span>Gæst</span> : <span>{props.user}</span>}
                </Link>
            </div> */}
            <div className={styles.logodiv}><img className={styles.logo} src={logo} /></div>
        </div>

    );
}

export default Top;
