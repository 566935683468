import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuth, loadGroups } from '../../store/actions';


class LoginToken extends Component {
    componentDidMount() {
        const { token, dist, vars } = this.props.match.params;
        //if (dist !== undefined && vars !== undefined) {
        //    this.props.setAuth(token, () => this.props.history.push('/' + dist + '/' + vars));
        //}
        //else {
        this.props.setAuth(token, () => {
            this.props.loadGroups(undefined);
            this.props.history.push('/');
        })
        //}
    }

    render() {
        return (
            <span></span>
        );
    }
}

const mapStateToProps = state => {
    return {
        authData: state.auth.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAuth: (token, handler) => dispatch(setAuth(token, handler)),
        loadGroups: (user) => dispatch(loadGroups(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginToken);
