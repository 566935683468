import axios from 'axios';
import * as actionTypes from './actionsTypes';
import url from '../../config';

export const setAuth = (token, handler) => {
  return dispatch => {
    axios.get(url + 'logintoken.php?token=' + token)
      .then(response => {
        if (response.data.session === null) {
          localStorage.removeItem('session');
        } else {
          localStorage.setItem('session', response.data.session);
          localStorage.setItem('user', response.data.user);
        }
        dispatch({
          type: actionTypes.SET_SESSION,
          data: { ...response.data, token: token }
        });
        handler();
      });
  }
};

export const reAuth = (session) => {
  return dispatch => {
    axios.get(url + 'reauth.php?session=' + session)
      .then(response => {
        if (response.data.return !== 'ok') {
          localStorage.removeItem('session');
          dispatch({
            type: actionTypes.SET_SESSION,
            data: {
              session: null,
              token: null,
              user: null
            }
          });
        }
      });
  }
}