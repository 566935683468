import axios from 'axios';
import * as actionTypes from './actionsTypes';
import url from '../../config';

export const loadGroups = (user) => {
  return (dispatch, getState) => {
    const session = getState().auth.data.session;
    axios.get(url + 'groups.php?session=' + session + '&user=' + user)
      .then(response => {
        dispatch({ type: actionTypes.LOAD_GROUP_DATA, data: response.data });
      });
  }
};