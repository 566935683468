import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  data: {
    label: '',
    text: '',
    groupname: '',
    alt: false,
    group: [
      {
        name: '',
        poster: 'default.jpg'
      }
    ],
    files: [],
    show: true
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VIDEO_DATA:
      return {
        ...state,
        data: { ...action.data }
      }
    default:
      return state;
  }
}

export default reducer;